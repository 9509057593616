import { useRouteMatch } from "react-router-dom";
import { LayerType } from ".";
import { PARTY_ROUTE } from "../../app/constants";
import { BotQueryParams } from "./MenuContent/shared/MenuList/MenuItem/types";
import { BOT_TYPES, BotType } from "./MenuContent/shared/MenuList/utils";

export interface BotLayerQueryParams extends BotQueryParams {
  botType: BotType;
  section: string;
}

export const BOT_ROUTE = "/party/:party/:botType/:uuid/:section";

const useCheckLayerMatch = (path: string) => {
  const route = useRouteMatch({ path });

  return Boolean(route);
};

const useCheckBotLayerMatch = (path: string) => {
  const route = useRouteMatch<BotLayerQueryParams>({ path });

  if (!route) return false;

  const { params } = route;

  const { botType } = params;

  const isBotLayer = BOT_TYPES.includes(botType);

  return isBotLayer;
};

export const useGetNavigationLayer = (): LayerType => {
  const isPartyLayer = useCheckLayerMatch(PARTY_ROUTE);

  const isBotLayer = useCheckBotLayerMatch(BOT_ROUTE);

  if (isBotLayer) return "bot";

  if (isPartyLayer) return "party";

  return "main";
};
