import { ApiModules, apiUrl } from "src/api";
import { buildQueryUrl } from "src/helpers/url";
import ResponseHandler from "src/state/network/ResponseHandler";

export const getCEXBots = async (party?: string) => {
  const url = buildQueryUrl("get-bots", { party });

  return ResponseHandler.handler({
    url: apiUrl(ApiModules.Empty, url),
    method: "GET",
  });
};

export const getCEXBot = async (bot_uuid: string) =>
  ResponseHandler.handler(
    {
      url: apiUrl(ApiModules.Bot, `${bot_uuid}/get-bot`),
      method: "GET",
    },
    "getCEXBot"
  );

export const getWatchList = async () =>
  ResponseHandler.handler(
    {
      url: apiUrl(ApiModules.WatchList, ""),
      method: "GET",
    },
    "getWatchList"
  );

export const getRecentParties = async () =>
  ResponseHandler.handler(
    {
      url: apiUrl(ApiModules.Empty, "get-bots-latest"),
      method: "GET",
    },
    "getRecentParties"
  );
