import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { NavigationMenu } from "src/components/NavigationMenu";
import { useClosePopup } from "src/hooks/useClosePopup";
import * as styles from "./style";

interface SideMenuProps {
  isMobile: boolean;
}

export const SideMenu = observer(({ isMobile }: SideMenuProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const sidebarRef = useRef<HTMLDivElement>(null);

  const hideMobSideBar = (bool: React.SetStateAction<boolean>) => {
    if (isMobile) setIsVisible(bool);
  };

  useClosePopup(sidebarRef, hideMobSideBar);

  return (
    <styles.Container ref={sidebarRef}>
      <styles.SideMenuButton isMenuVisible={isVisible} onClick={() => setIsVisible(!isVisible)} />

      <styles.SideMenuWrapper isVisible={isVisible}>
        <NavigationMenu />
      </styles.SideMenuWrapper>
    </styles.Container>
  );
});
