import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { DEXRoutes } from "src/app/constants";
import { getBotLink } from "src/components/NavigationMenu/MenuContent/shared/MenuList/utils";
import { Loader } from "src/components/shared/Loader";
import { AllBotsContext } from "src/context/AllBots";
import { useFindWidth } from "src/hooks/useFindWidth";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useBotsVirtualizer } from "../../hooks/useBotsVirtualizer";
import { Container, Content, ContentScrollWrapper } from "../shared/shared";
import { DEXBot } from "./DEXBot";
import { DEXBotMob } from "./DEXBotMob";
import * as styles from "./style";

const header = [
  { title: "Pair", icon: "" },
  { title: "Exchange", icon: "" },
  { title: "Last trade", icon: "" },
  { title: "Next trade", icon: "" },
  { title: "Message", icon: "" },
];

export const DEXBots = observer(() => {
  const { realtimeEnabled, dexBotsState: state } = useLateInitContext(AllBotsContext);

  const showMobVers = useFindWidth(1050);

  const {
    scrollParentRef,
    virtualizer: botsVirtualizer,
    getVirtualRowStyles,
  } = useBotsVirtualizer({
    heights: { mobile: 85, desktop: 88 },
    isMobile: showMobVers,
    size: state.bots.length,
  });

  useEffect(() => {
    if (realtimeEnabled) {
      state.resumeBotsFetching();
    } else {
      state.suspendBotsFetching();
    }

    return () => {
      if (realtimeEnabled) {
        state.suspendBotsFetching();
      }
    };
  }, [realtimeEnabled, state.resumeBotsFetching, state.suspendBotsFetching, state]);

  const dexBots = botsVirtualizer.virtualItems.map((virtualRow) => {
    const bot = state.bots[virtualRow.index];

    const statsLink = getBotLink({
      botType: "DEX",
      party: bot.party,
      uuid: bot.swap_bot_uuid,
      botTab: DEXRoutes.Stats,
    });

    const credential = {
      market: bot.market,
      UUID: bot.swap_bot_uuid,
      link: statsLink,
    };

    return showMobVers ? (
      <DEXBotMob
        key={bot.market}
        favorite
        toggleIsWatched={state.toggleIsWatched}
        credential={credential}
        {...bot}
        {...getVirtualRowStyles(virtualRow)}
      />
    ) : (
      <DEXBot
        key={bot.market}
        favorite
        toggleIsWatched={state.toggleIsWatched}
        credential={credential}
        {...bot}
        {...getVirtualRowStyles(virtualRow)}
      />
    );
  });

  return (
    <Container>
      {!showMobVers ? (
        <styles.DEXHeader
          favorite
          toggleWatchList={state.toggleWatchList.bind(state)}
          watchListEnabled={state.watchListEnabled}
          cells={header}
        />
      ) : null}
      <Content ref={scrollParentRef}>
        <ContentScrollWrapper $height={botsVirtualizer.totalSize}>{dexBots}</ContentScrollWrapper>
      </Content>
      <Loader show={state.showLoader && state.firstLoad} />
    </Container>
  );
});
