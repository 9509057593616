import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { LayerType } from "src/components/NavigationMenu";
import { PermissionWrapper } from "src/components/shared/PermissionWrapper";
import * as styles from "./style";
import { IMenuItem, LayerPathParams } from "./types";

interface QueryPartyParams {
  party?: string;
}

export interface MenuItemProps<T extends LayerType> {
  item: IMenuItem<T>;
  useGetCurrentTab: () => string | null;
  useGetPathParams: () => LayerPathParams<T>;
}

export const MenuItem = observer(
  <T extends LayerType>({ item, useGetCurrentTab, useGetPathParams }: MenuItemProps<T>) => {
    const { label, value, to, icon, abilityName } = item;

    const currentTab = useGetCurrentTab();

    const isActiveTab = currentTab === value;

    const pathParam = useGetPathParams();

    const linkPath = to(pathParam);

    const { party } = useParams<QueryPartyParams>();

    const Item = (
      <styles.MenuItem key={value} isSelected={isActiveTab}>
        <styles.NavLink to={linkPath} replace={isActiveTab}>
          <styles.IconWrapper isSelected={isActiveTab}>{icon ? icon() : null}</styles.IconWrapper>

          <styles.Label isSelected={isActiveTab}>{label}</styles.Label>
        </styles.NavLink>
      </styles.MenuItem>
    );

    return (
      <>
        {abilityName ? (
          <PermissionWrapper abilityName={abilityName}>{Item}</PermissionWrapper>
        ) : (
          Item
        )}
      </>
    );
  }
);
