import { BackLayerButton } from "./BackLayerButton";
import { MenuContent } from "./MenuContent";
import { useGetNavigationLayer } from "./hooks";
import * as styles from "./style";

export type LayerType = "main" | "party" | "bot";

export const NavigationMenu = () => {
  const layer = useGetNavigationLayer();

  return (
    <styles.SideMenuWrapper>
      <styles.SideMenuContent>
        {layer !== "main" ? <BackLayerButton layer={layer} /> : null}
        <MenuContent />
      </styles.SideMenuContent>
    </styles.SideMenuWrapper>
  );
};
