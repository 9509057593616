import { observer } from "mobx-react-lite";
import { useAppState } from "src/state";
import { BreadCrumbs } from "./BreadCrumbs";
import { Logo } from "./Logo";
import { ProfileSection, ProfileSectionProps } from "./ProfileSection";
import { SideMenu } from "./SideMenu";
import * as styles from "./style";

export interface HeaderProps extends ProfileSectionProps {
  isMobile: boolean;
}

export const Header = observer(({ themeToggler, isMobile }: HeaderProps) => {
  const { userState } = useAppState();

  if (!userState.isLoggedIn)
    return (
      <styles.HeaderContainer>
        <Logo isMobile={isMobile} />
      </styles.HeaderContainer>
    );

  return (
    <styles.HeaderContainer>
      <styles.Wrapper>
        {isMobile ? <SideMenu isMobile={isMobile} /> : null}

        <Logo isMobile={isMobile} />
      </styles.Wrapper>

      {!isMobile ? <BreadCrumbs /> : null}

      <ProfileSection themeToggler={themeToggler} />
    </styles.HeaderContainer>
  );
});
