import { observer } from "mobx-react-lite";
import { AllBotsContext } from "src/context/AllBots";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Party } from "./Party";
import * as styles from "./style";

export const PartiesList = observer(() => {
  const allBotsState = useLateInitContext(AllBotsContext);

  const { botsState: state } = allBotsState;

  return (
    <>
      {state.recentParties.length ? (
        <styles.PartiesList>
          {state.recentParties.map((party) => (
            <Party key={party.name} {...party} toggleSelection={state.togglePartySelection} />
          ))}
        </styles.PartiesList>
      ) : (
        <styles.EmptyListText>No new clients</styles.EmptyListText>
      )}
    </>
  );
});
