import { ApiModules, apiUrl } from "src/api";
import { Client } from "src/modules/bots";
import { OrderBookResponse } from "src/modules/exchange/orderBook";
import {
  CreatedFloatingOrder,
  FloatingOrder,
  FloatingOrderInfo,
  LimitOrder,
  MakeVolume,
  PlaceLiquidity,
  StopOrder,
  Transfer,
} from "src/modules/exchange/trade";
import ResponseHandler from "src/state/network/ResponseHandler";

interface RangeProps {
  start?: number;
  end?: number;
}

interface PublicTerminalProps {
  account_uuid?: string;
  pair?: string;
  exchange?: string;
}

export interface PublicTerminalWithRangeProps extends PublicTerminalProps, RangeProps {}

export interface AccTerminalProps {
  account_uuid: string;
  // account_name: string;
  pair?: string;
}

export interface OrderTerminalProps extends AccTerminalProps {
  order_id: string;
}

export interface OpenOrderTerminalProps extends AccTerminalProps, Omit<LimitOrder, "total_asset"> {}

interface OrdersTerminalProps extends AccTerminalProps {
  order_ids: string[];
}

interface AllOpenOrdersProps {
  pair: string;
  account_uuids: string[];
}

export interface TransfersReqProps extends PublicTerminalWithRangeProps {
  currency: string;
}

export const makeVolume = async (data: MakeVolume) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "make-volume"),
    method: "POST",
    data,
  });

export type LiquidityMode = "curve" | "linear";

export interface PlaceLiquidityRequestData extends PlaceLiquidity {
  symbol: string;
  precision_amount: number;
  precision_price: number;
}
export interface PlaceLiquidityRequestProps {
  data: PlaceLiquidityRequestData;
  mode: LiquidityMode;
}
export interface PlaceLiquidityResponse {}

export const placeLiquidity = async ({ data, mode }: PlaceLiquidityRequestProps) =>
  ResponseHandler.handler<PlaceLiquidityResponse>({
    url: apiUrl(ApiModules.Terminal, `place-liquidity?mode=${mode}`),
    method: "POST",
    data,
  });

export const fetchOrderBook = async ({ account_uuid, pair, exchange }: PublicTerminalProps) =>
  ResponseHandler.handler<OrderBookResponse>({
    url: apiUrl(ApiModules.Terminal, "/get-order-book"),
    method: "POST",
    data: { account_uuid, pair, exchange },
  });

export const fetchTradeHistory = async ({ pair, exchange, account_uuid }: PublicTerminalProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "/get-trades"),
    method: "POST",
    data: { pair, exchange, account_uuid },
  });

export const fetchLimitOrders = async ({ account_uuid, pair }: AccTerminalProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "get-my-open-orders"),
    method: "POST",
    data: {
      pair,
      account_uuid,
    },
  });

export const fetchLimitOrder = async ({ account_uuid, order_id, pair }: OrderTerminalProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "get-order"),
    method: "POST",
    data: {
      account_uuid,
      order_id,
      pair,
    },
  });

export const getAllOpenOrders = ({ pair, account_uuids }: AllOpenOrdersProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "/get-all-open-orders"),
    method: "POST",
    data: {
      pair,
      account_uuid: account_uuids,
    },
  });

export const fetchBalance = async ({ account_uuid }: AccTerminalProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "get-balance"),
    method: "POST",
    data: {
      account_uuid,
    },
  });

export const cancelOrder = async ({ account_uuid, pair, order_id }: OrderTerminalProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "cancel"),
    method: "POST",
    data: {
      account_uuid,
      pair,
      id: order_id,
    },
  });

export const cancelManyOrder = async ({ account_uuid, pair, order_ids }: OrdersTerminalProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "cancel-many"),
    method: "POST",
    data: {
      account_uuid,
      pair,
      ids: order_ids,
    },
  });

export const cancelAllOrders = async ({ account_uuid, pair }: AccTerminalProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "cancel-all"),
    method: "POST",
    data: {
      account_uuid,
      pair,
    },
  });

export interface LimitOrderResponse {
  // its order uuid
  data: string;
}

export const openBuyOrder = async (data: OpenOrderTerminalProps) =>
  ResponseHandler.handler<LimitOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "buy"),
    method: "POST",
    data,
  });

export const openSellOrder = async (data: OpenOrderTerminalProps) =>
  ResponseHandler.handler<LimitOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "sell"),
    method: "POST",
    data,
  });

export const transfer = async (transfer: Transfer) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "transfer"),
    method: "POST",
    data: { ...transfer },
  });

export const getCandles = async (
  exchange: string,
  pair: string,
  from: number,
  to: number,
  resolution: string = "5",
  countBack?: number,
  account_uuid?: string
) => {
  const minCandles = countBack ? `&countBack=${countBack}` : "";

  return ResponseHandler.handler({
    url: apiUrl(
      ApiModules.Terminal,
      `/get-candles?symbol=${pair}&from=${from}&to=${to}${minCandles}&resolution=${resolution}`
    ),
    method: "POST",
    data: { exchange, account_uuid },
  });
};

export interface GetClientsResponse {
  data: Client[];
}

export const getClients = async () =>
  ResponseHandler.handler<GetClientsResponse>({
    url: apiUrl(ApiModules.Terminal, "/clients"),
    method: "GET",
  });

export const getPairs = async (exchange: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "/get-pairs"),
    method: "POST",
    data: { exchange },
  });

export const getAlgOrders = async ({ account_uuid, pair }: AccTerminalProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "algo/stop/orders"),
    method: "POST",
    data: {
      account_uuid,
      filters: [{ field: "pair", op: "eq", value: pair }],
    },
  });

export const getAlgStatus = async ({ account_uuid, order_id }: OrderTerminalProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "algo/stop/status"),
    method: "POST",
    data: { account_uuid, orderId: order_id },
  });

export const cancelAlgoOrder = async ({ account_uuid, order_id }: OrderTerminalProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "algo/stop/cancel"),
    method: "POST",
    data: {
      account_uuid,
      orderId: order_id,
    },
  });

export const openStopOrder = async (account_uuid: string, stopOrder: StopOrder) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "algo/stop/create"),
    method: "POST",
    data: { account_uuid, ...stopOrder },
  });

export const fetchFeeRations = async (account_uuid: string, pair: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "get-fee-ratios"),
    method: "POST",
    data: { account_uuid, pair },
  });

export const fetchVolume24 = async ({ account_uuid, exchange, pair }: PublicTerminalProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "get-candle-24h"),
    method: "POST",
    data: { account_uuid, exchange, pair },
  });

export const fetchAccountHistory = async ({
  account_uuid,
  pair,
  start,
  end,
}: PublicTerminalWithRangeProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "get-trade-history"),
    method: "POST",
    data: { account_uuid, pair, start, end },
  });

export const fetchTransferHistory = async ({
  account_uuid,
  currency,
  start,
  end,
}: TransfersReqProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "get-transfers"),
    method: "POST",
    data: { account_uuid, currency, start, end },
  });

export interface GetFloatingOrdersRequest {
  account_uuid: string;
  pair: string;
}

interface GetFloatingOrdersResponse {
  data: CreatedFloatingOrder[];
}

export const getFloatingOrders = ({ account_uuid, pair }: GetFloatingOrdersRequest) =>
  ResponseHandler.handler<GetFloatingOrdersResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/floating/orders"),
    method: "POST",
    data: {
      account_uuid,
      filters: [{ field: "pair", op: "eq", value: pair }],
    },
  });

export interface OpenFloatingOrderRequest {
  data: FloatingOrder;
}

interface OpenFloatingOrderResponse {
  algo_order_id: number;
  isError: boolean;
  message: string;
}

export const openFloatingOrder = ({ data }: OpenFloatingOrderRequest) =>
  ResponseHandler.handler<OpenFloatingOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/floating/create"),
    method: "POST",
    data,
  });

export interface GetFloatingOrderStatusRequest {
  account_uuid: string;
  order_id: string;
}

interface GetFloatingOrderStatusResponse {
  data: FloatingOrderInfo;
  isError: boolean;
  message: string;
}

export const getFloatingOrderStatus = (data: GetFloatingOrderStatusRequest) =>
  ResponseHandler.handler<GetFloatingOrderStatusResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/floating/status"),
    method: "POST",
    data,
  });

export interface CancelFloatingOrderRequest extends GetFloatingOrderStatusRequest {}

interface CancelFloatingOrderResponse {}

export const cancelFloatingOrder = (data: CancelFloatingOrderRequest) =>
  ResponseHandler.handler<CancelFloatingOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/floating/cancel"),
    method: "POST",
    data,
  });
