import styled from "styled-components";

export const HeaderContainer = styled.header`
  width: 100%;
  height: ${({ theme }) => theme.dimensions.headerHeight};

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  box-sizing: border-box;
  padding: 3px 10px;

  background-color: ${({ theme }) => theme.contentBackgroundColor};
  border-bottom: 1px solid rgba(145, 158, 171, 0.2);
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 32px;
  padding: 0px 12px;
`;
