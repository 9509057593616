import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.nav`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-end;

  padding: 12px;
`;

export const CrumbsList = styled.ul`
  display: flex;
  flex-wrap: wrap;

  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Crumb = styled.li`
  color: ${({ theme }) => theme.lightGray};

  :not(:last-child)::after {
    color: currentColor;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    content: "/";
  }

  :last-child {
    color: ${({ theme }) => theme.darkGray};
  }
`;

export const CrumbLink = styled(Link)`
  color: currentColor;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;
