import { Route, Switch } from "react-router-dom";
import { BOT_NAV_PARAMS } from "./BotsNavigation";
import { BotNavigationList } from "./BotsNavigation/BotNavigationList";
import { MAIN_MENU_ITEMS, useGetMainCurrentTab, useGetMainPathParams } from "./MainPagesNavigation";
import { PARTY_MENU_ITEMS, useGetPartyCurrentTab, useGetPathParams } from "./PartyNavigation";
import { MenuItem } from "./shared/MenuList/MenuItem";
import { MenuList } from "./shared/MenuList/style";
import { PARTY_SECTION_PATH } from "./shared/MenuList/utils";

export const MenuContent = () => (
  <Switch>
    {BOT_NAV_PARAMS.map((el) => (
      <Route key={el.path} path={el.path} exact>
        <BotNavigationList key={el.path} botType={el.botType} />
      </Route>
    ))}

    <Route path={PARTY_SECTION_PATH} exact>
      <MenuList>
        {PARTY_MENU_ITEMS.map(({ value, ...other }) => (
          <MenuItem<"party">
            key={value}
            useGetCurrentTab={useGetPartyCurrentTab}
            useGetPathParams={useGetPathParams}
            item={{ value, ...other }}
          />
        ))}
      </MenuList>
    </Route>

    <Route path="/:section">
      <MenuList>
        {MAIN_MENU_ITEMS.map(({ value, ...other }) => (
          <MenuItem<"main">
            key={value}
            useGetCurrentTab={useGetMainCurrentTab}
            useGetPathParams={useGetMainPathParams}
            item={{ value, ...other }}
          />
        ))}
      </MenuList>
    </Route>
  </Switch>
);
