import { useParams } from "react-router-dom";
import { PartyPagesAbilities } from "src/app/constants";
import { NavigationTabsIcons } from "src/icons/NavigationTabIcons";
import { IMenuItem, PartyLinkParams } from "../shared/MenuList/MenuItem/types";
import { getPartySectionLink } from "../shared/MenuList/utils";

export interface PartyQueryParams {
  section: string;
  party: string;
}

export const PARTY_MENU_ITEMS: IMenuItem<"party">[] = [
  {
    label: "Bots",
    value: "bots",
    icon: NavigationTabsIcons.AllPartyBots,
    to: (pathParam: PartyLinkParams) => getPartySectionLink({ partySection: "Bots", ...pathParam }),
  },
  {
    abilityName: PartyPagesAbilities.MarketCheckDetailedView,
    label: "Market`s detailed",
    value: "market-detailed",
    icon: NavigationTabsIcons.MarketsDetailedInfo,
    to: (pathParam: PartyLinkParams) =>
      getPartySectionLink({ partySection: "MarketsDetailed", ...pathParam }),
  },
  {
    abilityName: PartyPagesAbilities.DashboardView,
    label: "Dashboard",
    value: "dashboard",
    icon: NavigationTabsIcons.Dashboard,
    to: (pathParam: PartyLinkParams) =>
      getPartySectionLink({ partySection: "Dashboard", ...pathParam }),
  },
  {
    abilityName: PartyPagesAbilities.DashboardAccountingView,
    label: "Dashboard V2",
    value: "dashboard-v2",
    icon: NavigationTabsIcons.Dashboard,
    to: (pathParam: PartyLinkParams) =>
      getPartySectionLink({ partySection: "DashboardV2", ...pathParam }),
  },
  {
    abilityName: PartyPagesAbilities.MultigridView,
    label: "Multi grid",
    value: "multigrid",
    icon: NavigationTabsIcons.MultiGrid,
    to: (pathParam: PartyLinkParams) =>
      getPartySectionLink({ partySection: "Multigrid", ...pathParam }),
  },
  {
    abilityName: PartyPagesAbilities.FundingView,
    label: "Funding",
    value: "funding",
    icon: NavigationTabsIcons.Funding,
    to: (pathParam: PartyLinkParams) =>
      getPartySectionLink({ partySection: "Funding", ...pathParam }),
  },
  {
    abilityName: PartyPagesAbilities.ModulesView,
    label: "Modules",
    value: "modules",
    icon: NavigationTabsIcons.Modules,
    to: (pathParam: PartyLinkParams) =>
      getPartySectionLink({ partySection: "Modules", ...pathParam }),
  },
  {
    abilityName: PartyPagesAbilities.CandleAnalyticsView,
    label: "Candle analytics",
    value: "candle-analytics",
    icon: NavigationTabsIcons.Analytics,
    to: (pathParam: PartyLinkParams) =>
      getPartySectionLink({ partySection: "Analytics", ...pathParam }),
  },
  {
    abilityName: PartyPagesAbilities.StrategiesView,
    label: "Strategies",
    value: "strategies",
    icon: NavigationTabsIcons.Expert,
    to: (pathParam: PartyLinkParams) =>
      getPartySectionLink({ partySection: "Strategies", ...pathParam }),
  },
];

export const useGetPartyCurrentTab = () => {
  const { section } = useParams<PartyQueryParams>();

  return section;
};

export const useGetPathParams = () => {
  const { party } = useParams<PartyQueryParams>();

  return { party };
};
