import { NativeCurrency, Price, Token } from "@uniswap/sdk-core";
import { ethers } from "ethers";
import { computed, makeAutoObservable, when } from "mobx";
import { computedFn } from "mobx-utils";
import { AggregatorV3Interface } from "src/contracts/AggregatorV3Interface";
import { AggregatorV3Interface__factory } from "src/contracts/factories/AggregatorV3Interface__factory";
import { makeLoggable } from "src/helpers/logger";
import { cacheComparer, isWhenTimeoutError } from "src/helpers/mobx";
import { chainErrorHandler } from "src/helpers/network/chain";
import { LogLevel, logDevError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { IChainProvider } from "src/state/chain/ChainProviderStore";
import { IObservableCache } from "src/state/shared/Cache";
import { ABSTRACT_NATIVE_CURRENCY, CacheOptions, tryParsePrice } from "../DEXV2Swap/shared";
import { AbstractStableCoin } from "../DEXV2Swap/shared/AbstractStableCoin";
import { WaitOptions } from "./TradePairUSDPriceProvider";

export interface GetNativeUSDPriceOptions extends CacheOptions, WaitOptions {}
export interface INativeUSDPriceProvider extends IDisposable {
  get nativeUSDPrice(): Price<NativeCurrency, Token> | undefined;
  getNativeUSDPrice(options?: GetNativeUSDPriceOptions): Promise<void>;
  get canQuery(): boolean;
}

const getPriceCacheKey = (chainId: number) => `native-price-${chainId}`;

export interface INativeUSDPriceParams {
  chainProvider: IChainProvider;
  priceCacheStore: IObservableCache<string>;
}

export class NativeUSDPriceProvider implements INativeUSDPriceProvider {
  private _chainProvider: IChainProvider;

  private _priceUSDCacheStore: IObservableCache<string>;

  private _loading = false;

  constructor({ chainProvider, priceCacheStore }: INativeUSDPriceParams) {
    makeAutoObservable<this, "_priceUSDCacheStore" | "_nativeUSDPrice">(this, {
      _priceUSDCacheStore: false,
      _nativeUSDPrice: computed({ equals: cacheComparer<string>() }),
    });

    this._chainProvider = chainProvider;

    this._priceUSDCacheStore = priceCacheStore;

    makeLoggable<any>(this, { nativeUSDPrice: true, _chainId: true });
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private get _provider() {
    return this._chainProvider.multicallProvider;
  }

  private get _chainsInfo() {
    return this._chainProvider.chainsInfo;
  }

  private get _chainId() {
    return this._chainProvider.chainID;
  }

  private get _priceCacheKey() {
    const chainId = this._chainId;
    if (!chainId) return null;

    const key = getPriceCacheKey(+chainId);

    return key;
  }

  private get _priceFeedAddress() {
    const chainId = this._chainId;
    const { nativePriceFeedMap } = this._chainsInfo;
    if (!chainId) return null;

    return nativePriceFeedMap[chainId];
  }

  private get _priceFeedContract() {
    const provider = this._provider;
    const address = this._priceFeedAddress;
    if (!provider || !address) return null;

    return AggregatorV3Interface__factory.connect(address, provider);
  }

  private _setCachedNativeUSDPrice = (price: string, cacheKey: string) => {
    this._priceUSDCacheStore.set(cacheKey, price);
  };

  private get _cachedNativeUSDPrice() {
    const cacheKey = this._priceCacheKey;
    if (!cacheKey) return undefined;

    return this._priceUSDCacheStore.get(cacheKey);
  }

  private get _nativeUSDPrice() {
    const cachedPrice = this._cachedNativeUSDPrice;
    return cachedPrice;
  }

  get nativeUSDPrice(): Price<NativeCurrency, Token> | undefined {
    const rawNativeUSDPrice = this._nativeUSDPrice;
    const chainId = this._chainId;
    const cacheKey = this._priceCacheKey;
    const rawPrice = this._getPairCachedRawPrice(cacheKey);
    if (!rawNativeUSDPrice || !chainId || !rawPrice) {
      return undefined;
    }

    const priceStableCoin = new AbstractStableCoin(+chainId);

    const nativeUSDPrice = tryParsePrice(
      rawNativeUSDPrice,
      priceStableCoin,
      ABSTRACT_NATIVE_CURRENCY
    );

    return nativeUSDPrice;
  }

  private get _nativePriceDeps() {
    const priceFeedContract = this._priceFeedContract;
    if (!priceFeedContract) return null;
    return { priceFeedContract };
  }

  get canQuery() {
    return Boolean(this._nativePriceDeps);
  }

  private _getNativeUsdPrice = async (priceFeedContract: AggregatorV3Interface) => {
    const [roundData, decimals] = await Promise.all([
      priceFeedContract.latestRoundData(),
      priceFeedContract.decimals(),
    ]);
    const [, rawNativePrice] = roundData;

    const nativePriceUsd = ethers.utils.formatUnits(rawNativePrice, decimals);
    return nativePriceUsd;
  };

  private _getCachedNativeUsdPrice = async (useCache: boolean = true) => {
    const priceDeps = this._nativePriceDeps;
    if (!priceDeps) return;

    const { priceFeedContract } = priceDeps;

    if (useCache) {
      const cachedPrice = this._cachedNativeUSDPrice;
      if (cachedPrice) {
        return cachedPrice;
      }
    }

    const price = await this._getNativeUsdPrice(priceFeedContract);
    return price;
  };

  private _getPairCachedRawPrice = computedFn(
    (cacheKey) => {
      if (!cacheKey) return undefined;

      return this._priceUSDCacheStore.get(cacheKey);
    },
    {
      equals: cacheComparer<string>(),
    }
  );

  private _getNativeUSDPrice = async (options: CacheOptions = {}) => {
    const cacheKey = this._priceCacheKey;
    if (!cacheKey) return;

    const nativePriceUsd = await this._getCachedNativeUsdPrice(options?.useCache);
    if (!nativePriceUsd) return;

    this._setCachedNativeUSDPrice(nativePriceUsd, cacheKey);
  };

  private _getWaitNativeUSDPrice = async (options: GetNativeUSDPriceOptions = {}) => {
    try {
      const { waitTimeout, ...otherOptions } = options;
      if (waitTimeout) {
        await when(() => this.canQuery, { timeout: waitTimeout });
      }
      await this._getNativeUSDPrice(otherOptions);
    } catch (err) {
      if (isWhenTimeoutError(err)) {
        logDevError("Timeout when waiting for getNativeUSDPrice, no price info will be fetched!", {
          level: LogLevel.Warning,
        });
      } else {
        throw err;
      }
    }
  };

  async getNativeUSDPrice(options: GetNativeUSDPriceOptions = {}) {
    if (this._loading) return;

    this._setLoading(true);
    try {
      await this._getWaitNativeUSDPrice(options);
    } catch (err) {
      chainErrorHandler(err);
    } finally {
      this._setLoading(false);
    }
  }

  destroy = () => {};
}
