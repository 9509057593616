import { MainRoutes } from "src/app/constants";
import { useGetBreadCrumbs } from "./hooks";
import * as styles from "./style";

export const BreadCrumbs = () => {
  const crumbs = useGetBreadCrumbs();

  const isLastItem = (index: number) => crumbs.length - 1 === index;

  return (
    <styles.Container>
      <styles.CrumbsList>
        <styles.Crumb>
          <styles.CrumbLink to={MainRoutes.Root}>Home</styles.CrumbLink>
        </styles.Crumb>

        {crumbs.map(({ name, to }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <styles.Crumb key={index}>
            {!isLastItem(index) ? <styles.CrumbLink to={to}>{name}</styles.CrumbLink> : name}
          </styles.Crumb>
        ))}
      </styles.CrumbsList>
    </styles.Container>
  );
};
