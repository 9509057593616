import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from "mobx";
import { computedFn } from "mobx-utils";
import { TransfersReqProps, fetchTransferHistory } from "src/api/bots/CEX/exchange";
import { getUTCRangeFromDuration, stringDateToUnix, unixToDateFormat } from "src/helpers/dateUtils";
import { getPathAndKey, getTargetValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { stringToSelectorValue } from "src/helpers/forms/selectors";
import {
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
} from "src/helpers/forms/types";
import { SelectorValue } from "src/modules/shared";
import { Account } from "src/modules/userManager";
import { RangePickerStore } from "src/state/shared/RangePicker";
import { required, validateData } from "src/validation-schemas";
import { DEFAULT_FUNDING_RANGE, FundingStore } from ".";
import { FundingType, NewFormManualFunding } from "./types";

interface ITransferReqConfig {
  exchange: string;
  accountUUID: string;
  currency: string;
}

interface IHistoryTransfer {
  address: string;
  amount: string;
  chain: string;
  currency: string;
  fee: string;
  id: string;
  status: string;
  time: string;
  txId: string;
  type: FundingType;
}

export interface ITransferWithID extends IHistoryTransfer {
  exchange: string;
  account_uuid: string;
  name?: string;
}

interface ITransfersMap {
  [key: string]: ITransferWithID[];
}

const selectorFields = ["exchange", "accountUUID", "currency"] as const;

type SelectorFields = (typeof selectorFields)[number];

class TransferListStore {
  private _transfersMap: ITransfersMap = {};

  isLoading = false;

  rangeState: RangePickerStore;

  mainState: FundingStore;

  transferConfig: ITransferReqConfig = {
    exchange: "",
    accountUUID: "",
    currency: "",
  };

  exchApiSupport = true;

  handlers: FormHandlers<ITransferReqConfig> = {};

  private _validation: FormValidation<ITransferReqConfig> = {
    accountUUID: required(),
    exchange: required(),
    currency: required(),
  };

  errors: FormErrors<ITransferReqConfig> = {};

  private _exchangeChangedReaction?: IReactionDisposer;

  private _selectShowTransfer: ITransferWithID | null = null;

  constructor(sate: FundingStore) {
    this.mainState = sate;

    this.rangeState = new RangePickerStore(this, DEFAULT_FUNDING_RANGE);

    makeAutoObservable(this);
  }

  get party() {
    return this.mainState.party;
  }

  get transferList() {
    const matrix = Object.values(this._transfersMap);

    return matrix.flatMap((el) => el);
  }

  get exchanges() {
    return this.mainState.accountState.exchOptions;
  }

  get exchangeSelected() {
    return Boolean(this.transferConfig.exchange);
  }

  get currentAccount() {
    return this.mainState.accountState.getSelectAccount(
      this.transferConfig.exchange,
      this.transferConfig.accountUUID
    );
  }

  get showTransfer() {
    if (this._selectShowTransfer)
      return {
        ...this._selectShowTransfer,
        time: unixToDateFormat(stringDateToUnix(this._selectShowTransfer.time), "FullDate"),
      };

    return undefined;
  }

  private _setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  getSelectTransfer = (currency: string, id: string) => {
    const currencyTransfers = this._transfersMap[currency];

    if (currencyTransfers) {
      return currencyTransfers.find((el) => el.id === id);
    }

    return null;
  };

  setTransferInFunding = (currency: string, id: string) => {
    const selectTransfer = this.getSelectTransfer(currency, id);

    if (selectTransfer) {
      const newFunding: NewFormManualFunding = {
        account_uuid: selectTransfer.account_uuid,
        amount: parseFloat(selectTransfer.amount),
        currency: selectTransfer.currency,
        exchange: selectTransfer.exchange,
        type: selectTransfer.type,
        time: stringDateToUnix(selectTransfer.time),
      };

      this.mainState.fundingFormCb(newFunding);
    }
  };

  setShowTransfer = (currency: string, id: string) => {
    const selectTransfer = this.getSelectTransfer(currency, id);

    if (selectTransfer)
      runInAction(() => {
        this._selectShowTransfer = selectTransfer;
      });
  };

  setTransfers = (transfers: IHistoryTransfer[]) => {
    if (!transfers.length) return;

    const transfersCurrency = transfers[0].currency;

    this._transfersMap[transfersCurrency] = transfers.map((el) => ({
      exchange: this.transferConfig.exchange,
      account_uuid: this.transferConfig.accountUUID,
      name: this.currentAccount?.label,

      ...el,
    }));
  };

  subscribe = () => {
    this._exchangeChangedReaction = reaction(
      () => this.getSelector("exchange"),
      () => {
        this._updateSelector("accountUUID", "");
        runInAction(() => {
          this.exchApiSupport = true;
        });
        this._checkExchangeSupport();
      }
    );
  };

  unsubscribe = () => {
    this._exchangeChangedReaction?.();
  };

  onSelectorChange = (field: SelectorFields) => (newValue: SelectorValue | null) => {
    if (!newValue) return;
    this._updateSelector(field, String(newValue.value));
  };

  getSelector = (field: SelectorFields) => {
    const [path, endKey] = getPathAndKey(field);

    const targetData = getTargetValueByPath(this.transferConfig, path);

    return targetData[endKey];
  };

  selectorValue = computedFn((field: SelectorFields) => {
    const [path, endKey] = getPathAndKey(field);

    const targetData = getTargetValueByPath(this.transferConfig, path);

    const currentValue = targetData[endKey];

    if (!currentValue) {
      return null;
    }
    return stringToSelectorValue(currentValue);
  });

  private _updateSelector = (field: SelectorFields, value: string) => {
    const [path, endKey] = getPathAndKey(field);

    const targetData = getTargetValueByPath(this.transferConfig, path);

    targetData[endKey] = value;
  };

  getHandler = (key: "currency"): FormFieldHandler => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);

      const targetData = getTargetValueByPath(this.transferConfig, path);

      this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        targetData[endKey] = getChangeEventValue(e);
      };
    }

    return this.handlers[key]!;
  };

  loadData = () => {};

  validate = (validateKeys?: string[]) =>
    validateData(this._validation, this.transferConfig, this.errors, validateKeys);

  submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate();

    if (valid && this.rangeState.start && this.rangeState.end) {
      this._setLoading(true);

      const reqProps: TransfersReqProps = {
        account_uuid: this.transferConfig.accountUUID,
        currency: this.transferConfig.currency,
        start: this.rangeState.start,
        end: this.rangeState.end,
      };

      try {
        const {
          isError,
          data: { data },
        } = await fetchTransferHistory(reqProps);

        if (!isError) {
          this.setTransfers(data);
        }
      } finally {
        this._setLoading(false);
      }
    }
  };

  private _getReqProps = (accountList: Account[]): TransfersReqProps => {
    const defaultRange = getUTCRangeFromDuration({ minutes: 1 });

    return {
      account_uuid: accountList[0].uuid,
      currency: "USDT",
      start: defaultRange[0]?.unix(),
      end: defaultRange[1]?.unix(),
    };
  };

  private _checkExchangeSupport = async () => {
    this._setLoading(true);

    if (this.transferConfig.exchange) {
      const account = this.mainState.accountState.accounts;

      const accountList = account[this.transferConfig.exchange] || [];

      if (accountList.length) {
        this._setLoading(true);

        const reqProps = this._getReqProps(accountList);

        try {
          const { isError, data } = await fetchTransferHistory(reqProps);

          if (!isError) {
            runInAction(() => {
              this.exchApiSupport = true;
            });
          } else if (isError) {
            // eslint-disable-next-line quotes
            const notSupp = data.includes('"not supported"');

            if (notSupp)
              runInAction(() => {
                this.exchApiSupport = false;
              });
          }
        } finally {
          this._setLoading(false);
        }
      }
    }
  };

  destroy = () => {};
}

export default TransferListStore;
