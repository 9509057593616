import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const SideMenuWrapper = styled.div`
  width: 85px;

  display: flex;
  justify-content: center;
  flex: 0 0 auto;

  border-right: 1px solid rgba(145, 158, 171, 0.2);
  background-color: ${({ theme }) => theme.contentBackgroundColor};

  overflow-y: auto;
  ${ScrollSize}
`;

export const SideMenuContent = styled.div`
  width: 100%;

  height: 100%;
  display: flex;
  flex-direction: column;
`;
